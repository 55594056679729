
import AuthModalMixin      from '@/mixins/auth_modal'
import AppConfig          from '@/shared/services/app_config'
import Records            from '@/shared/services/records'
import Session            from '@/shared/services/session'
import EventBus           from '@/shared/services/event_bus'
import Flash              from '@/shared/services/flash'
import AbilityService     from '@/shared/services/ability_service'
import RecordLoader       from '@/shared/services/record_loader'
import { capitalize, take, keys, every, orderBy, debounce } from 'lodash'
import { subDays, addDays, subWeeks, subMonths } from 'date-fns'

export default
  mixins: [ AuthModalMixin ]
  data: ->
    templates: []
    loaded: false
    processing: false
    trials: AppConfig.features.app.trials

  created: ->
    EventBus.$on 'signedIn', @init

  beforeDestroy: ->
    EventBus.$off 'signedIn', @init

  mounted: ->
    EventBus.$emit('content-title-visible', false)
    EventBus.$emit 'currentComponent',
      titleKey: 'templates.try_loomio'
      page: 'threadsPage'
      search:
        placeholder: @$t('navbar.search_all_threads')
    @init()

  watch:
    '$route.query': 'refresh'

  methods:
    init: ->
      Records.fetch(path: 'templates').then (data) =>
        @loaded = true

      @watchRecords
        key: 'templatesIndex'
        collections: ['templates']
        query: =>
          @templates = Records.templates.find(groupId: null)

    startDemo: (id) ->
      if Session.isSignedIn()
        @cloneTemplate(id)
      else
        @openAuthModal()

    cloneTemplate: (id) ->
      Flash.wait('templates.generating_demo')
      @processing = true
      Records.post
        path: 'templates/clone'
        params:
          id: id
      .then (data) =>
        Flash.success('templates.demo_created')
        @$router.push @urlFor(Records.groups.find(data.groups[0].id))
      .finally =>
        @processing = false

