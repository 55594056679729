
import Records from '@/shared/services/records'
import {slice} from 'lodash'
import PollCommonChartTable from '@/components/poll/common/chart/table'

export default
  components: {PollCommonChartTable}
  props:
    poll: Object
    options: Array

