
import Session from '@/shared/services/session'
import { orderBy } from 'lodash'

export default
  components:
    PollCommonDirective: -> import('@/components/poll/common/directive')

  props:
    stance: Object

  computed:
    canEdit: ->
      @stance.latest && @stance.participant() == Session.user()

