
import Records  from '@/shared/services/records'
import EventBus from '@/shared/services/event_bus'
import Flash   from '@/shared/services/flash'
import { head, filter, map, sortBy, isEqual } from 'lodash'

export default
  props:
    stance: Object

  data: ->
    pollOptions: []
    stanceChoices: []

  created: ->
    @watchRecords
      collections: ['pollOptions']
      query: (records) =>
        if @stance.poll().optionsDiffer(@pollOptions)
          @pollOptions = @poll.pollOptionsForVoting()
          @stanceChoices = map @pollOptions, (option) =>
            score: @stance.scoreFor(option)
            option: option
  methods:
    submit: ->
      @stance.stanceChoicesAttributes = map @stanceChoices, (choice) =>
        poll_option_id: choice.option.id
        score: choice.score
      actionName = if !@stance.castAt then 'created' else 'updated'
      @stance.save()
      .then =>
        Flash.success "poll_#{@stance.poll().pollType}_vote_form.stance_#{actionName}"
        EventBus.$emit "closeModal"
      .catch => true

  computed:
    poll: -> @stance.poll()
    reasonTooLong: ->
      !@stance.poll().allowLongReason && @stance.reason && @stance.reason.length > 500
