
import AppConfig       from '@/shared/services/app_config'
import Session         from '@/shared/services/session'
import Records         from '@/shared/services/records'
import UserHelpService from '@/shared/services/user_help_service'
import Flash from '@/shared/services/flash'

export default
  methods:
    togglePinned: ->
      if @user.experiences['sidebar']
        Records.users.saveExperience('sidebar', false)
      else
        Records.users.saveExperience('sidebar', true)

    toggleDark: ->
      if @isDark
        Records.users.saveExperience('darkMode', false)
        @$vuetify.theme.dark = false
      else
        Records.users.saveExperience('darkMode', true)
        @$vuetify.theme.dark = true

    defaultDark: ->
      (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)

    signOut: ->
      Session.signOut()

  computed:
    isDark:   -> @$vuetify.theme.dark
    version:  -> AppConfig.version
    release:  -> AppConfig.release
    siteName: -> AppConfig.theme.site_name
    user:     -> Session.user()
    helpLink: -> UserHelpService.helpLink()
    showHelp: -> AppConfig.features.app.help_link
    showContact: -> AppConfig.features.app.show_contact
    showBeta: -> !AppConfig.features.app.thread_page_v3

