import { render, staticRenderFns } from "./collab_editor.vue?vue&type=template&id=3ee897e6&lang=pug&"
import script from "./collab_editor.vue?vue&type=script&lang=coffee&"
export * from "./collab_editor.vue?vue&type=script&lang=coffee&"
import style0 from "./collab_editor.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import EmojiPicker from '@/components/emoji/picker.vue'
import LinkPreviews from '@/components/thread/link_previews.vue'
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {EmojiPicker,LinkPreviews,VAlert,VBtn,VCard,VCardActions,VCardText,VCardTitle,VIcon,VLayout,VMenu,VSpacer,VTextField})
