
import AbilityService from '@/shared/services/ability_service'

import { pick, pickBy, assign, compact } from 'lodash'
import CommentService from '@/shared/services/comment_service'
import EventService from '@/shared/services/event_service'
import Session from '@/shared/services/session'

export default
  props:
    event: Object
    eventable: Object

  data: ->
    confirmOpts: null
    showReplyForm: false
    newComment: null
    commentActions: CommentService.actions(@eventable, @)
    eventActions: EventService.actions(@event, @)

  computed:
    dockActions: ->
      assign(
        pickBy @commentActions, (v) -> v.dock
      ,
        pick @eventActions, []
      )

    menuActions: ->
      actions = assign(
        pick @eventActions, ['pin_event', 'unpin_event', 'move_event', 'copy_url']
      ,
        pickBy @commentActions, (v) -> v.menu
      )
      pick actions, ['pin_event', 'unpin_event', 'reply_to_comment',  'admin_edit_comment', 'copy_url', 'notification_history', 'move_event', 'discard_comment', 'undiscard_comment']

