

export default
  props:
    stance: Object
    poll: Object
    size:
      type: Number
      default: 20

  computed:
    pollOption: -> @stance.pollOption()

