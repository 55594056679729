
import Records  from '@/shared/services/records'
import AppConfig  from '@/shared/services/app_config'
import EventBus from '@/shared/services/event_bus'
import Flash  from '@/shared/services/flash'
import {uniq, without, isEqual} from 'lodash'

export default
  props:
    poll: Object
    close: Function

  data: ->
    originalLength: @poll.pollOptionNames.length
    isDisabled: false

  methods:
    submit: ->
      # there's a race condition here because of the way we are adding poll form options
      # hence the setTimeout
      setTimeout =>
        @poll.addOptions()
        .then =>
          @poll.removeOrphanOptions()
          Flash.success "poll_common_add_option.form.options_added"
          @close()

