
import Records        from '@/shared/services/records'
import EventBus       from '@/shared/services/event_bus'
import AbilityService from '@/shared/services/ability_service'
import UserModalMixin from '@/mixins/user_modal'

import { isEmpty }     from 'lodash'
import { approximate } from '@/shared/helpers/format_time'

export default
  mixins: [UserModalMixin]

  data: ->
    user: {}
    isMembershipsFetchingDone: false
    groups: []
    canContactUser: false
    loadingGroupsForExecuting: false

  created: ->
    @init()
    EventBus.$emit 'currentComponent', {page: 'userPage'}
    Records.users.findOrFetchById(@$route.params.key).then @init, (error) ->
      EventBus.$emit 'pageError', error

  methods:
    approximate: approximate
    init: ->
      if @user = (Records.users.find(@$route.params.key) or Records.users.find(username: @$route.params.key))[0]
        Records.remote.get('profile/contactable', user_id: @user.id).then =>
          @canContactUser = true
        EventBus.$emit 'currentComponent', {title: @user.name, page: 'userPage'}
        @loadGroupsFor(@user)
        @watchRecords
          key: @user.id
          collections: ['groups', 'memberships']
          query: (store) =>
            @groups = @user.groups()

    loadGroupsFor: (user) ->
      @loadingGroupsForExecuting = true
      Records.memberships.fetchByUser(user).then =>
        @loadingGroupsForExecuting = false

  computed:
    isEmptyUser: -> isEmpty @user

