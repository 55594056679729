import { render, staticRenderFns } from "./outcome_panel.vue?vue&type=template&id=4a1b302e&lang=pug&"
import script from "./outcome_panel.vue?vue&type=script&lang=coffee&"
export * from "./outcome_panel.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import ActionDock from '@/components/common/action_dock.vue'
import AttachmentList from '@/components/thread/attachment_list.vue'
import DocumentList from '@/components/document/list.vue'
import FormattedText from '@/components/common/formatted_text.vue'
import LinkPreviews from '@/components/thread/link_previews.vue'
import MidDot from '@/components/common/mid_dot.vue'
import Space from '@/components/common/space.vue'
import TimeAgo from '@/components/common/time_ago.vue'
import UserAvatar from '@/components/user/avatar.vue'
import { VAlert } from 'vuetify/lib/components/VAlert';
installComponents(component, {ActionDock,AttachmentList,DocumentList,FormattedText,LinkPreviews,MidDot,Space,TimeAgo,UserAvatar,VAlert})
