import { render, staticRenderFns } from "./panel.vue?vue&type=template&id=5ba65138&lang=pug&"
import script from "./panel.vue?vue&type=script&lang=coffee&"
export * from "./panel.vue?vue&type=script&lang=coffee&"
import style0 from "./panel.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import PollCommonStanceIcon from '@/components/poll/common/stance_icon.vue'
installComponents(component, {PollCommonStanceIcon})
