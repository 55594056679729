
import PageLoader         from '@/shared/services/page_loader'
import Records from '@/shared/services/records'
import EventBus     from '@/shared/services/event_bus'
import { fieldFromTemplate } from '@/shared/helpers/poll'

export default
  props:
    poll: Object

  data: ->
    stances: []
    per: 25
    loader: null

  created: ->
    @loader = new PageLoader
      path: 'stances'
      order: 'orderAt'
      params:
        per: @per
        poll_id: @poll.id

    @loader.fetch(@page)

    @watchRecords
      collections: ['stances']
      query: => @findRecords()

  computed:
    totalPages: ->
      Math.ceil(parseFloat(@loader.total) / parseFloat(@per))

  watch:
    page: ->
      @loader.fetch(@page).then => @findRecords()
      @findRecords()

  methods:
    findRecords: ->
      chain = Records.stances.collection.chain().
        find(pollId: @poll.id).
        find(latest: true).
        find(revokedAt: null)

      if @loader.pageWindow[@page]
        if @page == 1
          chain = chain.find(orderAt: {$gte: @loader.pageWindow[@page][0]})
        else
          chain = chain.find(orderAt: {$jbetween: @loader.pageWindow[@page]})
        chain = chain.simplesort('orderAt', true)
      else
        @stances = []

      @stances = chain.data()

  computed:
    page:
      get: -> parseInt(@$route.query.page) || 1
      set: (val) ->
        @$router.replace
          query:
            page: val

    totalPages: ->
      Math.ceil(parseFloat(@loader.total) / parseFloat(@per))

