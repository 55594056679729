import { render, staticRenderFns } from "./arrangement_form.vue?vue&type=template&id=7a51bf5b&lang=pug&"
import script from "./arrangement_form.vue?vue&type=script&lang=coffee&"
export * from "./arrangement_form.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import DismissModalButton from '@/components/common/dismiss_modal_button.vue'
import Space from '@/components/common/space.vue'
import SubmitOverlay from '@/components/common/submit_overlay.vue'
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {DismissModalButton,Space,SubmitOverlay,VAlert,VBtn,VCard,VCardActions,VCardSubtitle,VCardTitle,VRadio,VRadioGroup,VSpacer,VSubheader})
