import { render, staticRenderFns } from "./actions_panel.vue?vue&type=template&id=e245eace&lang=pug&"
import script from "./actions_panel.vue?vue&type=script&lang=coffee&"
export * from "./actions_panel.vue?vue&type=script&lang=coffee&"
import style0 from "./actions_panel.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import CommentForm from '@/components/thread/comment_form.vue'
import JoinGroupButton from '@/components/group/join_button.vue'
import PollCommonStartForm from '@/components/poll/common/start_form.vue'
import PollProposalCompleteForm from '@/components/poll/proposal/complete_form.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {CommentForm,JoinGroupButton,PollCommonStartForm,PollProposalCompleteForm,VBtn,VDivider,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider})
