import { render, staticRenderFns } from "./page.vue?vue&type=template&id=3b9e31d8&lang=pug&"
import script from "./page.vue?vue&type=script&lang=coffee&"
export * from "./page.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import SubmitOverlay from '@/components/common/submit_overlay.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import ValidationErrors from '@/components/common/validation_errors.vue'
installComponents(component, {SubmitOverlay,VBtn,VCard,VCardActions,VCardSubtitle,VCardText,VCardTitle,VContainer,VMain,VSpacer,VTextField,VTextarea,ValidationErrors})
