import { render, staticRenderFns } from "./card_header.vue?vue&type=template&id=caa1492a&lang=pug&"
import script from "./card_header.vue?vue&type=script&lang=coffee&"
export * from "./card_header.vue?vue&type=script&lang=coffee&"
import style0 from "./card_header.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import TimeAgo from '@/components/common/time_ago.vue'
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {TimeAgo,VBreadcrumbs,VIcon,VSpacer})
