
import Session  from '@/shared/services/session'
import Records  from '@/shared/services/records'
import EventBus from '@/shared/services/event_bus'
import PollCommonDirective from '@/components/poll/common/directive'
import PollService from '@/shared/services/poll_service'
import { pick } from 'lodash'

import PollCommonChartPoll from '@/components/poll/common/chart/poll'
import PollCommonChartCount from '@/components/poll/common/chart/count'
import PollCommonChartMeeting from '@/components/poll/common/chart/meeting'
import PollCommonChartProposal from '@/components/poll/common/chart/proposal'
import PollCommonChartRankedChoice from '@/components/poll/common/chart/ranked_choice'

export default
  components:
    {PollCommonChartPoll,
     PollCommonChartCount,
     PollCommonChartMeeting,
     PollCommonChartProposal,
     PollCommonChartRankedChoice}

  props:
    poll: Object

  data: ->
    votersByOptionId: {}
    options: {}

  created: ->
    @watchRecords
      collections: ['pollOptions']
      query: =>
        if Session.isSignedIn()
          Records.users.fetchAnyMissingById(@poll.decidedVoterIds())
        @options = @poll.pollOptionsForResults()

  computed:
    pollType: -> @poll.pollType
