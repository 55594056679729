import { render, staticRenderFns } from "./proposal.vue?vue&type=template&id=58e4dc7b&lang=pug&"
import script from "./proposal.vue?vue&type=script&lang=coffee&"
export * from "./proposal.vue?vue&type=script&lang=coffee&"
import style0 from "./proposal.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import PollCommonIconPanel from '@/components/poll/common/icon/panel.vue'
installComponents(component, {PollCommonIconPanel})
