
import EventBus from '@/shared/services/event_bus'
import AppConfig from '@/shared/services/app_config'
import Session from '@/shared/services/session'

import {sum, map, sortBy, find, compact, uniq} from 'lodash'

export default
  props:
    poll: Object
    zone: Object
    options: Array

  data: ->
    decidedVoters: []

  created: ->
    @watchRecords
      collections: ['users']
      query: (store) =>
        @decidedVoters = @poll.decidedVoters()

  methods:
    scoreColor: (score) ->
      switch score
        when 2 then AppConfig.pollColors.proposal[0]
        when 1 then AppConfig.pollColors.proposal[1]
        when 0 then AppConfig.pollColors.proposal[2]

    bgColor: (score) ->
      switch score
        when 2 then "rgba(0, 209, 119, 0.5)"
        when 1 then "rgba(246, 168, 43, 0.5)"

    barLength: (count) ->
      ((count * 32)) + 'px'

    classForScore: (score) ->
      switch score
        when 2 then 'poll-meeting-chart__cell--yes'
        when 1 then 'poll-meeting-chart__cell--maybe'
        else
          'poll-meeting-chart__cell--no'

  computed:
    currentUserTimeZone: ->
      Session.user().timeZone
