
import { some } from 'lodash'
export default
  props:
    actions: Object
    small: Boolean

  computed:
    canPerformAny: ->
      some @actions, (action) -> action.canPerform()

