
import Records from '@/shared/services/records'
import { fieldFromTemplate, myLastStanceFor } from '@/shared/helpers/poll'
import { max, values, orderBy, compact } from 'lodash'

export default
  props:
    poll: Object
    options: Array

  data: ->
    votersByOptionId: {}

  computed:
    simple: ->
      ['proposal', 'count', 'poll'].includes(@poll.pollType)

  created: ->
    @watchRecords
      collections: ['users']
      query: =>
        @options.forEach (option) =>
          @votersByOptionId[option.id] = option.voters()

