
import AppConfig      from '@/shared/services/app_config'
import Session        from '@/shared/services/session'
import Records        from '@/shared/services/records'
import EventBus       from '@/shared/services/event_bus'
import AbilityService from '@/shared/services/ability_service'
import LmoUrlService  from '@/shared/services/lmo_url_service'
import openModal      from '@/shared/helpers/open_modal'
import UserService    from '@/shared/services/user_service'
import Flash   from '@/shared/services/flash'
import { includes, uniq, debounce } from 'lodash'

export default
  data: ->
    user: null
    originalUser: null
    existingEmails: []

  created: ->
    @init()
    EventBus.$emit 'currentComponent', { titleKey: 'profile_page.edit_profile', page: 'profilePage'}
    EventBus.$on 'updateProfile', @init
    EventBus.$on 'signedIn', @init

  beforeDestroy: ->
    EventBus.$off 'updateProfile', @init
    EventBus.$off 'signedIn', @init

  computed:
    showHelpTranslate: -> AppConfig.features.app.help_link
    availableLocales: -> AppConfig.locales
    actions: -> UserService.actions(Session.user(), @)
    emailExists: -> includes(@existingEmails, @user.email)

  methods:
    init: ->
      return unless Session.isSignedIn()
      @originalUser = Session.user()
      @user = @originalUser.clone()
      Session.updateLocale(@user.locale)

    changePicture: ->
      openModal
        component: 'ChangePictureForm'

    changePassword: ->
      @openChangePasswordModal(@user)

    openDeleteUserModal: ->
      @isDeleteUserModalOpen = true

    closeDeleteUserModal: ->
      @isDeleteUserModalOpen = false

    openSendVerificationModal: ->
      openModal
        component: 'ConfirmModal'
        props:
          confirm:
            submit: => Records.users.sendMergeVerificationEmail(@user.email)
            text:
              title:    'merge_accounts.modal.title'
              raw_helptext: @$t('merge_accounts.modal.helptext', sourceEmail: @originalUser.email, targetEmail: @user.email)
              submit:   'merge_accounts.modal.submit'
              flash:    'merge_accounts.modal.flash'

    checkEmailExistence: debounce ->
      return if @originalUser.email == @user.email
      Records.users.checkEmailExistence(@user.email).then (res) =>
        if res.exists
          @existingEmails = uniq(@existingEmails.concat([res.email]))
    , 250

    submit: ->
      Records.users.updateProfile(@user)
      .then =>
        Flash.success 'profile_page.messages.updated'
        @init()
      .catch => true

