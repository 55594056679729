import { render, staticRenderFns } from "./polls_panel.vue?vue&type=template&id=383d5228&lang=pug&"
import script from "./polls_panel.vue?vue&type=script&lang=coffee&"
export * from "./polls_panel.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import Loading from '@/components/common/loading.vue'
import PollCommonPreview from '@/components/poll/common/preview.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {Loading,PollCommonPreview,VBtn,VCard,VIcon,VLayout,VList,VListItem,VListItemTitle,VMenu,VPagination,VTextField})
