
import StrandList from '@/components/strand/list.vue'
import NewComment from '@/components/strand/item/new_comment.vue'
import NewDiscussion from '@/components/strand/item/new_discussion.vue'
import DiscussionEdited from '@/components/strand/item/discussion_edited.vue'
import PollCreated from '@/components/strand/item/poll_created.vue'
import StanceCreated from '@/components/strand/item/stance_created.vue'
import StanceUpdated from '@/components/strand/item/stance_updated.vue'
import OutcomeCreated from '@/components/strand/item/outcome_created.vue'
import StrandItemRemoved from '@/components/strand/item/removed.vue'
import StrandLoadMore from '@/components/strand/load_more.vue'
import OtherKind from '@/components/strand/item/other_kind.vue'
import RangeSet from '@/shared/services/range_set'
import EventBus from '@/shared/services/event_bus'
import { camelCase, first, last, some, sortedUniq, sortBy, without } from 'lodash'

export default
  name: 'strand-list'
  props:
    loader: Object
    newestFirst: Boolean
    collection:
      type: Array
      required: true

  components:
    StrandList: StrandList
    NewDiscussion: NewDiscussion
    NewComment: NewComment
    PollCreated: PollCreated
    StanceCreated: StanceCreated
    StanceUpdated: StanceUpdated
    OutcomeCreated: OutcomeCreated
    OtherKind: OtherKind
    StrandLoadMore: StrandLoadMore
    DiscussionEdited: DiscussionEdited
    StrandItemRemoved: StrandItemRemoved

  computed:
    directedCollection: ->
      if @newestFirst
        @collection.reverse()
      else
        @collection

  methods:
    isFocused: (event) ->
      (event.depth == 1 && event.position == @loader.focusAttrs.position) ||
      (event.positionKey == @loader.focusAttrs.positionKey) ||
      (event.sequenceId == @loader.focusAttrs.sequenceId) ||
      (event.eventableType == 'Comment' && event.eventableId == @loader.focusAttrs.commentId)

    positionKeyPrefix: (event) ->
      if event.depth < 1
        event.positionKey.split('-').slice(0, event.depth - 1)
      else
        null

    componentForKind: (kind) ->
      camelCase if ['stance_created', 'stance_updated', 'discussion_edited', 'new_comment', 'outcome_created', 'poll_created', 'new_discussion'].includes(kind)
        kind
      else
        'other_kind'

    classes: (event) ->
      return [] unless event
      ["lmo-action-dock-wrapper",
       "positionKey-#{event.positionKey}",
       "sequenceId-#{event.sequenceId}",
       "position-#{event.position}"]

