
import Records from '@/shared/services/records'
import Session from '@/shared/services/session'
import ReactionService from '@/shared/services/reaction_service'
import {merge, capitalize, difference, keys, throttle, startsWith, each, compact} from 'lodash'
import { colonToUnicode, stripColons, imgForEmoji, srcForEmoji, emojiSupported } from '@/shared/helpers/emojis'

export default
  props:
    model: Object
    small: Boolean

  data: ->
    diameter: (@small && 16) || 24
    maxNamesCount: 10
    reactionHash: {all: []}
    emojiSupported: emojiSupported

  created: ->
    ReactionService.enqueueFetch(@model)

  mounted: ->
    @watchRecords
      collections: ['reactions']
      query: (store) =>
        @reactionHash = {all: []}
        each Records.reactions.find(@reactionParams), (reaction) =>
          unless @reactionHash[reaction.reaction]?
            @reactionHash[reaction.reaction] = []
          if user = Records.users.find(reaction.userId)
            @reactionHash[reaction.reaction].push(user)
            @reactionHash['all'].push(user)
          true

    # Records.reactions.enqueueFetch(@reactionParams)

  computed:
    reactionParams: ->
      reactableType: capitalize(@model.constructor.singular)
      reactableId: @model.id

    reactionTypes: ->
      compact difference keys(@reactionHash), ['all']

    myReaction: ->
      return unless Session.isSignedIn()
      Records.reactions.find(merge({}, @reactionParams, userId: Session.userId))[0]

    otherReaction: ->
      Records.reactions.find(merge({}, @reactionParams, {userId: {'$ne': Session.userId}}))[0]

    reactionTypes: ->
      difference keys(@reactionHash), ['all']

  methods:
    srcForEmoji: srcForEmoji
    stripColons: stripColons
    colonToUnicode: colonToUnicode
    removeMine: (reaction) ->
      mine = Records.reactions.find(merge({}, @reactionParams,
        userId:   Session.user().id
        reaction: reaction
      ))[0]
      mine.destroy() if mine

    translate: (shortname) ->
      title = emojiTitle(shortname)
      if startsWith(title, "reactions.") then shortname else title

    countFor: (reaction) ->
      if @reactionHash[reaction]?
        @reactionHash[reaction].length - @maxNamesCount
      else
        0

