
import EventBus from '@/shared/services/event_bus'
import Flash   from '@/shared/services/flash'
import { optionColors, optionImages } from '@/shared/helpers/poll'
import { isEqual, map } from 'lodash'

export default
  props:
    stance: Object
  data: ->
    selectedOptionId: @stance.pollOptionId()
    optionColors: optionColors()
    optionImages: optionImages()
    debug: @$route.query.debug
    optionGroups: []
  created: ->
    @watchRecords
      collections: ['pollOptions']
      query: (records) =>
        if @stance.poll().optionsDiffer(@pollOptions)
          options = @stance.poll().pollOptions()
          @optionGroups = if options.length == 4
            [[options[0], options[1]], [options[2], options[3]]]
          else
            [options]

  computed:
    reasonTooLong: ->
      !@stance.poll().allowLongReason && @stance.reason && @stance.reason.length > 500

  methods:
    submit: ->
      actionName = if !@stance.castAt then 'created' else 'updated'
      @stance.stanceChoicesAttributes = [{poll_option_id: @selectedOptionId}]
      @stance.save()
      .then =>
        Flash.success "poll_#{@stance.poll().pollType}_vote_form.stance_#{actionName}"
        EventBus.$emit "closeModal"
      .catch => true

    isSelected: (option) ->
      @selectedOptionId == option.id

    classes: (option) ->
      if @selectedOptionId
        if @selectedOptionId == option.id
          ['elevation-5']
        else
          ['poll-proposal-vote-form__button--not-selected', 'elevation-1']
      else
        ['elevation-1']

