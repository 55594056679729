
import Session        from '@/shared/services/session'
import AbilityService from '@/shared/services/ability_service'
import { map, sortBy, filter } from 'lodash'
import AppConfig from '@/shared/services/app_config'
import Records from '@/shared/services/records'
import Flash   from '@/shared/services/flash'

export default
  props:
    discussion: Object
    close: Function

  data: ->
    clone: @discussion.clone()

  methods:
    submit: ->
      @clone.save()
      .then =>
        @close()
        Flash.success("discussion_form.messages.updated")

