
import AppConfig from '@/shared/services/app_config'

export default
  data: ->
    privacyUrl: AppConfig.theme.privacy_url
    helpUrl: AppConfig.theme.help_url
    termsUrl: AppConfig.theme.terms_url
    frontPageUrl: AppConfig.baseUrl + "?frontpage"
    showFrontPage: AppConfig.baseUrl != "https://www.loomio.org/"

