
export default
  props:
    stance: Object
  computed:
    maxLength: ->
      if @stance.poll().allowLongReason
        undefined
      else
        500
