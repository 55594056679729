
import EventBus from '@/shared/services/event_bus'
import { iconFor }          from '@/shared/helpers/poll'
import PollCommonDirective from '@/components/poll/common/directive.vue'
import Flash   from '@/shared/services/flash'
import { sortBy } from 'lodash'

export default
  props:
    stance: Object
  components:
    PollCommonDirective: PollCommonDirective

  methods:
    submit: ->
      actionName = if !@stance.castAt then 'created' else 'updated'
      @stance.save()
      .then =>
        Flash.success "poll_#{@stance.poll().pollType}_vote_form.stance_#{actionName}"
        @close()
      .catch (error) => true

  computed:
    icon: ->
      iconFor(@stance.poll())
