
import Records from '@/shared/services/records'
import Session from '@/shared/services/session'
import AppConfig from '@/shared/services/app_config'
import FileUploader from '@/shared/services/file_uploader'
import FilesList from './files_list.vue'
import EventBus from '@/shared/services/event_bus'
import I18n from '@/i18n'
import { convertToMd } from '@/shared/services/format_converter'

import Blockquote from '@tiptap/extension-blockquote'
import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import CodeBlock from '@tiptap/extension-code-block'
import Code from '@tiptap/extension-code'
import Document from '@tiptap/extension-document'
import Dropcursor from '@tiptap/extension-dropcursor'
import GapCursor from '@tiptap/extension-gapcursor'
import HardBreak from '@tiptap/extension-hard-break'
import Heading from '@tiptap/extension-heading'
import History from '@tiptap/extension-history'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import Strike from '@tiptap/extension-strike'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Table from '@tiptap/extension-table'
# import TaskList from '@tiptap/extension-task-list'
import {CustomTaskItem} from './extension_custom_task_item'
import {CustomTaskList} from './extension_custom_task_list'
import TextStyle from '@tiptap/extension-text-style'
# import TextAlign from '@tiptap/extension-text-align'
import Text from '@tiptap/extension-text'
import Underline from '@tiptap/extension-underline'
import {CustomMention} from './extension_mention'
import {CustomImage} from './extension_image'
import {Iframe} from './extension_iframe'

import { Editor, EditorContent, VueRenderer } from '@tiptap/vue-2'

import {getEmbedLink} from '@/shared/helpers/embed_link.coffee'

import { CommonMentioning, HtmlMentioning, MentionPluginConfig } from './mentioning.coffee'
import SuggestionList from './suggestion_list'
import Attaching from './attaching.coffee'
import {compact, uniq, throttle, difference, reject, uniqBy} from 'lodash'
import TextHighlightBtn from './text_highlight_btn'
import TextAlignBtn from './text_align_btn'
import { TextAlign } from './extension_text_align'
import { Highlight } from './extension_highlight'

isValidHttpUrl = (string) ->
  url = undefined
  try
    url = new URL(string)
  catch _
    return false
  url.protocol == 'http:' or url.protocol == 'https:'

export default
  mixins: [CommonMentioning, HtmlMentioning, Attaching]
  props:
    model: Object
    field: String
    label: String
    placeholder: String
    maxLength: Number
    shouldReset: Boolean
    autofocus: Boolean

  components: {
    EditorContent
    TextAlignBtn
    TextHighlightBtn
    SuggestionList
    FilesList
  }

  data: ->
    loading: true
    socket: null
    count: 0
    editor: null
    expanded: false
    closeEmojiMenu: false
    linkUrl: ""
    iframeUrl: ""
    linkDialogIsOpen: false
    iframeDialogIsOpen: false
    fetchedUrls: []

  computed:
    format: ->
      @model["#{@field}Format"]

  mounted: ->
    @expanded = Session.user().experiences['html-editor.expanded']
    @model.beforeSave = => @updateModel()
    @editor = new Editor
      editorProps:
        scrollThreshold: 100
        scrollMargin: 100
      autofocus: @autofocus
      extensions: [
        Blockquote
        Bold
        BulletList
        CodeBlock
        CustomImage.configure({attachFile: @attachFile, attachImageFile: @attachImageFile})
        Document
        Dropcursor
        GapCursor
        Heading
        Highlight.configure({ multicolor: true })
        History
        HorizontalRule
        Italic
        Iframe
        Link
        ListItem
        OrderedList
        Paragraph
        Placeholder.configure(placeholder: @placeholder)
        Strike
        Text
        Table
        TableHeader
        TableRow
        TableCell
        CustomTaskList
        CustomTaskItem
        CustomMention.configure(MentionPluginConfig.bind(@)())
        TextStyle
        TextAlign.configure({ types: ['heading', 'paragraph'] })
        Underline
      ]
      content: @model[@field]
      onUpdate: =>
        @checkLength() if @maxLength
        @scrapeLinkPreviews() if @model.isNew()

  watch:
    'shouldReset': 'reset'

  methods:
    checkLength: ->
      @model.saveDisabled = @editor.storage.characterCount.characters() > @maxLength

    setCount: (count) ->
      @count = count

    tiptapAddress: ->
      if @model.isNew()
        compact([AppConfig.theme.channels_uri, 'tiptap', @model.constructor.singular, 'new', @model.groupId, @model.discussionId, @model.parentId, Session.user().secretToken]).join('/')
      else
        [AppConfig.theme.channels_uri, 'tiptap', @model.constructor.singular, @model.id, (@model.secretToken || Session.user().secretToken)].join('/')

    selectedText: ->
      state = @editor.state
      selection = @editor.state.selection
      { from, to } = selection
      state.doc.textBetween(from, to, ' ')

    reset: ->
      @editor.chain().clearContent().run()
      @resetFiles()
      @model.beforeSave = => @updateModel()

    convertToMd: ->
      if confirm I18n.t('formatting.markdown_confirm')
        convertToMd(@model, @field)
        Records.users.saveExperience('html-editor.uses-markdown')

    toggleExpanded: ->
      @expanded = !@expanded
      Records.users.saveExperience('html-editor.expanded', @expanded)

    setLinkUrl: ->
      if @linkUrl
        @linkUrl = "http://".concat(@linkUrl) unless @linkUrl.includes("://")
        @editor.chain().setLink(href: @linkUrl).focus().run()
        @fetchLinkPreviews([@linkUrl])
        @linkUrl = null
      @linkDialogIsOpen = false

    setIframeUrl: () ->
      return unless isValidHttpUrl(@iframeUrl)
      @editor.chain().setIframe(src: getEmbedLink(@iframeUrl)).focus().run()
      @iframeUrl = null
      @iframeDialogIsOpen = false

    emojiPicked: (shortcode, unicode) ->
      @editor.chain()
          .insertContent(unicode)
          .focus()
          .run()
      @closeEmojiMenu = false

    updateModel: ->
      return unless @format == 'html'
      @model[@field] = @editor.getHTML()
      @model[@field] = '' if @model[@field] == '<p></p>'
      @updateFiles()

    removeLinkPreview: (url) ->
      @model.linkPreviews = reject(@model.linkPreviews, (p) -> p.url == url)

    scrapeLinkPreviews: throttle ->
      parser = new DOMParser()
      doc = parser.parseFromString(@editor.getHTML(), 'text/html')
      @fetchLinkPreviews difference((Array.from(doc.querySelectorAll('a')).map (el) => el.href), @fetchedUrls)
    ,
      500
    ,
      {leading: false}

    fetchLinkPreviews: (urls) ->
      if urls.length
        @fetchedUrls = uniq @fetchedUrls.concat(urls)
        Records.remote.post('link_previews', {urls: urls}).then (data) =>
          @model.linkPreviews = uniqBy(@model.linkPreviews.concat(data.previews), 'url')

  beforeDestroy: ->
    @editor.destroy() if @editor
    # @socket.close() if @socket

