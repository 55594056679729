import { render, staticRenderFns } from "./panel.vue?vue&type=template&id=979d3b62&lang=pug&"
import script from "./panel.vue?vue&type=script&lang=coffee&"
export * from "./panel.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import PollCommonPercentVoted from '@/components/poll/common/percent_voted.vue'
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {PollCommonPercentVoted,VSubheader})
