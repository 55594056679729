
import Session        from '@/shared/services/session'
import Records        from '@/shared/services/records'
import EventBus       from '@/shared/services/event_bus'
import AbilityService from '@/shared/services/ability_service'
import Flash  from '@/shared/services/flash'
import { last } from 'lodash'

export default
  props:
    comment: Object
    autofocus: Boolean

  data: ->
    actor: Session.user()
    canSubmit: true
    shouldReset: false

  computed:
    placeholder: ->
      if @comment.parentId
        @$t('comment_form.in_reply_to', {name: @comment.parent().authorName()})
      else
        @$t('comment_form.aria_label')

  methods:
    handleIsUploading: (val) ->
      @canSubmit = !val

    submit: ->
      @comment.save()
      .then =>
        @$emit('comment-submitted')
        @shouldReset = !@shouldReset
        flashMessage = if !@comment.isNew()
                        'comment_form.messages.updated'
                      else if @comment.isReply()
                        'comment_form.messages.replied'
                      else
                        'comment_form.messages.created'
        Flash.success flashMessage, {name: @comment.parent().authorName() if @comment.isReply()}
      .catch => true

