
import PollService    from '@/shared/services/poll_service'
import AbilityService from '@/shared/services/ability_service'
import EventBus       from '@/shared/services/event_bus'
import EventService from '@/shared/services/event_service'
import { pick, pickBy, assign } from 'lodash'

export default
  props:
    event: Object
    collapsed: Boolean
    eventable: Object

  created: ->
    EventBus.$on 'stanceSaved', => EventBus.$emit 'refreshStance'
    @watchRecords
      collections: ["stances", "polls"]
      query: (records) =>
        @pollActions = PollService.actions(@poll)
        @eventActions = EventService.actions(@event, @)
        @myStance = @poll.myStance()

  beforeDestroy: ->
    EventBus.$off 'showResults'
    EventBus.$off 'stanceSaved'

  data: ->
    buttonPressed: false
    myStance: null
    pollActions: null
    eventActions: null

  computed:
    poll: -> @eventable
    showResults: -> @poll.showResults()

    menuActions: ->
      assign(
        pickBy @eventActions, (v) -> v.menu
      ,
        pickBy @pollActions, (v) -> v.menu
      )
    dockActions: ->
      pickBy @pollActions, (v) -> v.dock

