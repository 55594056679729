import { render, staticRenderFns } from "./recipients_autocomplete.vue?vue&type=template&id=3dd09e18&lang=pug&"
import script from "./recipients_autocomplete.vue?vue&type=script&lang=coffee&"
export * from "./recipients_autocomplete.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import Space from '@/components/common/space.vue'
import UserAvatar from '@/components/user/avatar.vue'
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {Space,UserAvatar,VAutocomplete,VChip,VIcon,VListItem,VListItemAvatar,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle})
