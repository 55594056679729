import { render, staticRenderFns } from "./email_form.vue?vue&type=template&id=4940d022&lang=pug&"
import script from "./email_form.vue?vue&type=script&lang=coffee&"
export * from "./email_form.vue?vue&type=script&lang=coffee&"
import style0 from "./email_form.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTextField } from 'vuetify/lib/components/VTextField';
import ValidationErrors from '@/components/common/validation_errors.vue'
installComponents(component, {VBtn,VTextField,ValidationErrors})
