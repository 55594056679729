
import AppConfig from '@/shared/services/app_config'
import EventBus from '@/shared/services/event_bus'
import Session from '@/shared/services/session'
import Records from '@/shared/services/records'
import marked from 'marked'
import md5 from 'md5'

export default
  data: ->
    notice: false
    showNotice: false
    reload: false

  mounted: ->
    setInterval =>
      Records.fetch
        path: 'boot/version'
        params:
          version: AppConfig.version
          release: AppConfig.release
          now: Date.now()
      .then(@eatData)
    ,
      1000 * 60 * 5
    EventBus.$on 'systemNotice', @eatData
    @eatData({version: AppConfig.version, notice: AppConfig.systemNotice})

  methods:
    eatData: (data) ->
      @reload = data.reload
      @notice = data.notice
      @showNotice = @reload || (@notice && !Session.user().hasExperienced(md5(@notice)))

    accept: ->
      @showNotice = false
      @notice && Records.users.saveExperience(md5(@notice))
      if @reload
        setTimeout ->
          location.reload()
        , 100

