
import Session        from '@/shared/services/session'
import StanceService        from '@/shared/services/stance_service'
import AbilityService from '@/shared/services/ability_service'
import openModal from '@/shared/helpers/open_modal'
import LmoUrlService  from '@/shared/services/lmo_url_service'

export default
  props:
    event: Object
    eventable: Object
    collapsed: Boolean

  computed:
    actor: -> @event.actor()
    actorName: -> @event.actorName()
    poll: -> @eventable.poll()
    showResults: -> @poll.showResults()
    actions: -> StanceService.actions(@eventable)
    componentType:  ->
      if @actor
        'router-link'
      else
        'div'
    link: ->
      LmoUrlService.event @event
