import { render, staticRenderFns } from "./form.vue?vue&type=template&id=534f5fb6&lang=pug&"
import script from "./form.vue?vue&type=script&lang=coffee&"
export * from "./form.vue?vue&type=script&lang=coffee&"
import style0 from "./form.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import DismissModalButton from '@/components/common/dismiss_modal_button.vue'
import GroupAvatar from '@/components/group/avatar.vue'
import LmoTextarea from '@/components/lmo_textarea/lmo_textarea.vue'
import MidDot from '@/components/common/mid_dot.vue'
import Space from '@/components/common/space.vue'
import SubmitOverlay from '@/components/common/submit_overlay.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import ValidationErrors from '@/components/common/validation_errors.vue'
installComponents(component, {DismissModalButton,GroupAvatar,LmoTextarea,MidDot,Space,SubmitOverlay,VBtn,VCard,VCardActions,VCardSubtitle,VCardTitle,VCheckbox,VImg,VLayout,VOverlay,VProgressCircular,VRadio,VRadioGroup,VSpacer,VSubheader,VTab,VTabItem,VTabs,VTextField,ValidationErrors})
