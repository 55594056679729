import { render, staticRenderFns } from "./votes_panel.vue?vue&type=template&id=bcd0d330&lang=pug&"
import script from "./votes_panel.vue?vue&type=script&lang=coffee&"
export * from "./votes_panel.vue?vue&type=script&lang=coffee&"
import style0 from "./votes_panel.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import FormattedText from '@/components/common/formatted_text.vue'
import Loading from '@/components/common/loading.vue'
import PollCommonStanceChoice from '@/components/poll/common/stance_choice.vue'
import PollCommonStanceChoices from '@/components/poll/common/stance_choices.vue'
import TimeAgo from '@/components/common/time_ago.vue'
import UserAvatar from '@/components/user/avatar.vue'
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {FormattedText,Loading,PollCommonStanceChoice,PollCommonStanceChoices,TimeAgo,UserAvatar,VLayout,VPagination,VSpacer})
