
import EventBus from '@/shared/services/event_bus'
import Flash   from '@/shared/services/flash'
import { sortBy, find, matchesProperty, take, map, isEqual, each } from 'lodash'

export default
  props:
    stance: Object
  data: ->
    pollOptions: []

  created: ->
    @watchRecords
      collections: ['pollOptions']
      query: (records) =>
        if @stance.poll().optionsDiffer(@pollOptions)
          @pollOptions = @sortPollOptions()

  methods:
    submit: ->
      selected = take @pollOptions, @numChoices
      @stance.stanceChoicesAttributes = map selected, (option, index) =>
        poll_option_id: option.id
        score:         @numChoices - index
      actionName = if !@stance.castAt then 'created' else 'updated'
      @stance.save()
      .then =>
        Flash.success "poll_#{@stance.poll().pollType}_vote_form.stance_#{actionName}"
        EventBus.$emit "closeModal"
      .catch => true

    sortPollOptions: ->
      if @stance && @stance.castAt
        sortBy @stance.poll().pollOptions(), (o) => -@stance.scoreFor(o)
      else
        @stance.poll().pollOptionsForVoting()

  computed:
    numChoices: -> @stance.poll().customFields.minimum_stance_choices
    reasonTooLong: ->
      !@stance.poll().allowLongReason && @stance.reason && @stance.reason.length > 500

