import { render, staticRenderFns } from "./membership_request.vue?vue&type=template&id=009faa24&lang=pug&"
import script from "./membership_request.vue?vue&type=script&lang=coffee&"
export * from "./membership_request.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import MidDot from '@/components/common/mid_dot.vue'
import Space from '@/components/common/space.vue'
import TimeAgo from '@/components/common/time_ago.vue'
import UserAvatar from '@/components/user/avatar.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {MidDot,Space,TimeAgo,UserAvatar,VBtn,VIcon,VListItem,VListItemAction,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle})
